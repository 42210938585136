define('client/components/joco-select', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var computed = Ember.computed;
    exports.default = Component.extend({
        // TODO -- validate inputs
        // interface
        label: null,
        options: [],
        selected: null,

        // html events
        changeListener: null,

        didInsertElement: function didInsertElement() {
            var _this = this;

            var ret = this._super.apply(this, arguments);

            var element = document.querySelector('#' + this.get('selectId'));
            var changeListener = function changeListener() {
                var htmlSelected = element.value;
                var selected = _this.get('options').find(function (x) {
                    return x.get('label') == htmlSelected;
                });
                _this.set('selected', selected);
            };
            this.set('changeListener', changeListener);

            element.addEventListener('change', changeListener);
            changeListener();

            return ret;
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            // TODO -- test this properly
            // element.removeEventListener('change', this.get('changeListener'));
        },


        // implementation
        selectId: computed('elementId', function () {
            return 'select-' + this.get('elementId');
        })
    });
});