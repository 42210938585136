define('client/routes/recipes/show', ['exports', 'client/lib/recipes'], function (exports, _recipes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return _recipes.recipeManager.getRecipe(params.recipeId);
        }
    });
});