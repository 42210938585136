define('client/controllers/resume', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /*
  
    {
      company: '',
      title: '',
      startDate: '',
      endDate: '',
      details: [
        "",
      ],
    },
  */

  var jobDetails = [{
    company: 'Lyft',
    title: 'Senior Software Engineer',
    startDate: '10/14/2019',
    endDate: '6/5/2020',
    details: ["Implemented campaign support for Gmail AMP, kicked off brainstorming for innovative content via Gmail AMP and Gmail Promo markup.", "Managed vendor relationships to uncover potential in the email, SMS, and push channels. Evaluated new sources of performance data to bring additional experimentation insights.", "Partnered with Supply Management team to achieve cost savings on existing business relationships, and prioritize further channel cost improvements based on business need.", "Guided internal teams on best messaging practices: reduced hard bounces, successfully spun up new IPs for segmented email domains, and uncovered other inbox performance shortfalls."]
  }, {
    company: 'Wish',
    title: 'Senior Software Engineer',
    startDate: '05/10/2017',
    endDate: '10/11/2019',
    details: ["Estimated impact of projects and experiments over $40m in GMV", "Helped re-think our approach to campaigns analysis, bringing better signal to our experiment results and over $10m in value", "Tech lead working with 5 engineers, responsible for all user messaging at Wish", "Proven ability to expand into new domains, in one case driving an increase in email open rates of over 100% via an understanding of email infrastructure and IP pooling", "Data-driven in both initial project specs as well as post-analysis and iteration, using a mix of internal tooling and one-off SQL", "Redesigned our full suite of emails, with an emphasis on quality and compatibility across all email clients", "Ran over 50 promotional campaigns in coordination with team members from copy, design, translations, and email deliverability"]
  }, {
    company: 'Zenefits',
    title: 'Staff Software Engineer',
    startDate: '08/14/2014',
    endDate: '05/05/2017',
    details: ["Developed the original underwriting models and logic, improving transparency of insurance requirements and reducing the number of rejected applications", "Grew the new group enrollments team to 5 engineers", "Introduced our `snippets` repository to accountably track data fixes made to production servers",
    // "Optimized insertions to our rates tables, cutting time and space requirements by 66%",
    "Implemented Ember CLI for our internal and client facing apps, allowing our multiple 20,000 line JS files to be broken down to more manageable pieces",
    // "Optimized part of the Django migration system (custom version) leading to a ~30% speedup in migration speed",
    "Reduced SQL call load in production by ~10% via a series of fixes in product code", "Reduced full test suite time by 4 hours via optimizations in our Django signals and test setup",
    // "Helped run the H1-702 security bounty event during DEF CON 24",
    "Identified and fixed multiple severe security issues throughout our product", "Led the development of Zenefits' new Compliance Assistant product, including all front-end UI, the back-end domain services, and pre-release load testing"]
  }, {
    company: 'Addepar',
    title: 'Software Engineer',
    startDate: '06/19/2013',
    endDate: '08/08/2014',
    details: ["Led a re-write of ~100 transaction components from a native Java Swing client to an Ember.js web client", "Built CRUD APIs using Java and Jersey to selectively expose domain models to the client",
    // "Created an internal-only API and tool for client administration (Java, Jersey, Gradle)",
    "Developed a script for anonymizing production data based on a whitelist approach (Java reflection, Python Fabric library)"]
  }];

  exports.default = Ember.Controller.extend({
    jobDetails: jobDetails
  });
});