define('client/controllers/games/tic_tac_toe', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    var EmberObject = Ember.Object;


    /*
    
    classes
        controller
        board
        player
    
    tests on each
    
    */

    var GameManager = EmberObject.extend({
        board: null,
        playerA: null,
        playerB: null

        // TODO -- how to structure the run loop but let the UI view and interact?
    });

    var arrayOfSize = function arrayOfSize(size) {
        var ret = [];

        for (var i = 0; i < size; i += 1) {
            ret.push(null);
        }

        return ret;
    };

    var RowOwner = EmberObject.extend({
        /*
             Simplified way of tracking the potential winners for each row/column/diagonal.
             Each section must be filled completely, so the first player to place a piece in
            a given row is the only one who can possibly win it in a given game.
         */
        owner: null, // Player
        ownedCount: 0,

        recordOwner: function recordOwner(owner) {
            var currentOwner = this.get('owner');

            if (!owner) {
                this.set('owner', owner);
                this.incrementProperty('ownedCount');
            } else if (currentOwner == owner) {
                this.incrementProperty('ownedCount');
            } else {
                return;
            }
        },

        getWinner: function getWinner(boardSize) {
            if (this.get('ownedCount') == boardSize) {
                return this.get('owner');
            }
        }
    });

    var Board = EmberObject.extend({
        // interface
        size: null,
        players: null,

        // implementation
        board: computed('size', function () {
            var size = this.get('size');
            var board = [];

            for (var i = 0; i < size; i += 1) {
                var row = [];
                for (var j = 0; j < size; j += 1) {
                    row.push(null);
                }
                board.push(row);
            }

            return board;
        }),

        currentPlayerIndex: 0,
        currentPlayer: computed('currentPlayerIndex', 'players', function () {
            // TODO -- validations
            return this.get('players')[this.get('currentPlayerIndex')];
        }),

        rowOwners: computed('size', function () {
            return arrayOfSize(this.get('size')).map(function () {
                return RowOwner.create();
            });
        }),
        colOwners: computed('size', function () {
            return arrayOfSize(this.get('size')).map(function () {
                return RowOwner.create();
            });
        }),
        forwardDiagOwner: computed('size', function () {
            return RowOwner.create();
        }),
        reverseDiagOwner: computed('size', function () {
            return RowOwner.create();
        }),

        currentWinner: Ember.computed(function () {
            var size = this.get('size');

            var rowWinner = this.get('rowOwners').find(function (owner) {
                return owner.getWinner(size);
            });
            var colWinner = this.get('colOwners').find(function (owner) {
                return owner.getWinner(size);
            });
            var forwardDiagWinner = this.get('forwardDiagOwner').getWinner(size);
            var reverseDiagWinner = this.get('reverseDiagOwner').getWinner(size);

            return rowWinner || colWinner || forwardDiagWinner || reverseDiagWinner;
        }),

        isValidPosition: function isValidPosition(row, col) {
            var size = this.get('size');

            if (row < 0 && row >= size) {
                return false;
            }

            if (col < 0 && col >= size) {
                return false;
            }

            var board = this.get('board');
            if (board[row][col] != null) {
                return false;
            }

            return true;
        },

        placePiece: function placePiece(player, row, col) {
            if (!this.isValidPosition(row, col)) {
                return "Position is taken"; // also OOB, but UI can't trigger that
            }

            this.get('board')[row][col] = player;
            this.get('rowOwners')[row].recordOwner(player);
            this.get('colOwners')[col].recordOwner(player);
            if (row == col) {
                this.get('forwardDiagOwner').recordOwner(player);
            }
            if (row + col == this.get('size') - 1) {
                this.get('forwardDiagOwner').recordOwner(player);
            }

            var newIndex = (this.get('currentPlayerIndex') + 1) % this.get('players.length');
            this.set('currentPlayerIndex', newIndex);

            return null;
        }
    });

    var Player = EmberObject.extend({
        playerType: null,
        name: null,
        token: null,

        makeMove: function makeMove(board) {
            // review the state of a game and then make a move
            // not a major concern here, but in other games it'd also be important to provide a history of moves up til the point
        }
    });
    Player.reopenClass({
        subclasses: [],

        generate: function generate(name, token) {
            return this.create({
                name: name,
                token: token
            });
        },

        extend: function extend() {
            var ret = this._super.apply(this, arguments);

            this.subclasses.push(ret);

            return ret;
        }
    });

    var HumanPlayer = Player.extend({
        makeMove: function makeMove(board) {}
    });
    HumanPlayer.reopenClass({ playerType: 'Human Player' });

    // let NaiveBot = Player.extend({
    //     makeMove: function(board) {

    //     },
    // });
    // NaiveBot.reopenClass({ playerType: 'Naive Bot' });

    // let ExpertBot = NaiveBot.extend({ // TODO -- implement logic and extend player instead of naive bot
    // });
    // ExpertBot.reopenClass({ playerType: 'Expert Bot' });


    exports.default = Ember.Controller.extend({
        boardSize: 3,
        playerTypeA: Ember.computed.alias('selectedPlayerA.value'),
        selectedPlayerA: EmberObject.create(),
        playerTypeB: Ember.computed.alias('selectedPlayerB.value'),
        selectedPlayerB: EmberObject.create(),

        playerClasses: Ember.computed(function () {
            return Player.subclasses.map(function (x) {
                return EmberObject.create({
                    label: x.playerType,
                    value: x
                });
            });
        }),

        gameStatus: computed('board.currentPlayer', 'board.currentWinner', function () {
            var currentPlayer = this.get('board.currentPlayer');
            var currentWinner = this.get('board.currentWinner');

            if (currentWinner) {
                return 'Player ' + currentPlayer.name + ' has won!';
            } else {
                return 'Waiting on ' + currentPlayer.name + ' to make a move';
            }
        }),

        board: computed('boardSize', function () {
            var defaultPlayerType = Player.subclasses[0];
            var playerTypeA = this.get('playerTypeA') || defaultPlayerType;
            var playerTypeB = this.get('playerTypeB') || defaultPlayerType;

            return Board.create({
                size: Number(this.get('boardSize')),
                players: [playerTypeA.generate("Player A", "👌🏻"), playerTypeB.generate("Player B", "😈")]
            });
        }),

        actions: {
            startGame: function startGame() {
                // TODO -- validations

                var uiBoardSize = this.get('uiBoardSize');
                this.set('boardSize', uiBoardSize);
            },

            selectCell: function selectCell(row, col) {
                this.get('board').placePiece(this.get('board.currentPlayer'), row, col);
                alert(1);
            }
        }
    });
});