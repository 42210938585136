define('client/controllers/games/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            notYet: function notYet() {
                alert('Ooops! You have to wait :)');
            }
        }
    });
});