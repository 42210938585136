define('client/components/chess-cell', ['exports', 'client/controllers/games/chess'], function (exports, _chess) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        piece: null,

        isPawn: Ember.computed.equal('piece.piece', _chess.PAWN),

        isBishop: Ember.computed.equal('piece.piece', _chess.BISHOP),
        isRook: Ember.computed.equal('piece.piece', _chess.ROOK),
        isKnight: Ember.computed.equal('piece.piece', _chess.KNIGHT),

        isKing: Ember.computed.equal('piece.piece', _chess.KING),
        isQueen: Ember.computed.equal('piece.piece', _chess.QUEEN)
    });
});