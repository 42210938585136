define('client/components/chess-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var WHITE = 'white';
    var BLACK = 'black';

    exports.default = Ember.Component.extend({
        color: null,

        isWhite: Ember.computed.equal('color', WHITE),
        isBlack: Ember.computed.equal('color', BLACK)
    });
});