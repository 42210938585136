define('client/lib/albums', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /*
        {
            'name': '',
            'photos': [''],
        },
    */

    var AlbumManager = Ember.Object.extend({
        albums: Ember.computed(function () {
            return [{
                'id': 'sunset-at-ocean-beach',
                'name': 'Sunset at Ocean Beach',
                'photos': ['https://i.imgur.com/Hy3EvBb.jpg', // cliff house w/ winding railing along the way
                'https://i.imgur.com/LsIdYTb.jpg', // classic car
                'https://i.imgur.com/dqhTfxr.jpg', // motorcycle against the sun
                'https://i.imgur.com/1Cm8DXa.jpg', // ship in the clouds
                'https://i.imgur.com/6PLjBKa.jpg', // rock against the sun
                'https://i.imgur.com/jH3wOB0.jpg', // waves crashing over the rocks, looking at the marin headlands
                'https://i.imgur.com/X2AEk0T.jpg', // lands end
                'https://i.imgur.com/5NXu2KU.jpg']
            }, {
                'id': 'some-graffiti-and-industry',
                'name': 'Some graffiti and industry',
                'photos': ['https://i.imgur.com/a9atPLl.jpg', // graffiti boy and dog
                'https://i.imgur.com/j71tF2I.jpg', // someones home
                'https://i.imgur.com/dN055hn.jpg', // smokestack
                'https://i.imgur.com/F7mpFSg.jpg', // muni garage
                'https://i.imgur.com/vDBsRip.jpg', // building no more
                'https://i.imgur.com/JqEFEgZ.jpg', // y so srs?
                'https://i.imgur.com/GIpUTfR.jpg', // flowers n paint
                'https://i.imgur.com/GkCyuhP.jpg']
            }, {
                'id': 'basic-sf',
                'name': 'Basic SF',
                'photos': ['https://i.imgur.com/JozcT5x.jpg', // pier 48
                'https://i.imgur.com/JhOA3Lb.jpg', // SoFi train
                'https://i.imgur.com/zMwl7GF.jpg', // chillin in the bay boat
                'https://i.imgur.com/zHXRSkL.jpg', // bouj by the basic
                'https://i.imgur.com/nY9GD5X.jpg', // ~
                'https://i.imgur.com/91F3xhC.jpg', // casper
                'https://i.imgur.com/1BMIEDy.jpg', // boba guys
                'https://i.imgur.com/CQpZE8J.jpg', // holla salesforce
                'https://i.imgur.com/7594CZr.jpg']
            }, {
                'id': 'color-factory',
                'name': 'Color Factory',
                'photos': ['https://i.imgur.com/MzVhUt9.jpg', // viv ines rainbow
                'https://i.imgur.com/jZ3ISWp.jpg', // cookies
                'https://i.imgur.com/9kSuFVa.jpg', // stripes and rainbows
                'https://i.imgur.com/WEAg7Lk.jpg', // juan swirls
                'https://i.imgur.com/HpM8DTd.jpg', // drawing cray
                'https://i.imgur.com/7WBUhBZ.jpg', // light tubes
                'https://i.imgur.com/oGy2LNt.jpg', // colored lights viv
                'https://i.imgur.com/754bEjq.jpg', // chill bruh
                'https://i.imgur.com/WaDNb16.jpg', // #slamdunk
                'https://i.imgur.com/7zWSIoO.jpg']
            }, {
                'id': 'crazy-strong-climbers-climbing-crazy-hard-problems',
                'name': 'comp @ Dogpatch Boulders',
                'photos': ['https://i.imgur.com/Nc1PZMX.jpg', // swiiiiiing
                'https://i.imgur.com/2pubUu9.jpg', // girls
                'https://i.imgur.com/8RYYXgx.jpg', // right b4 the flop
                'https://i.imgur.com/Yh6dSiF.jpg', // dis a hold?
                'https://i.imgur.com/ocJqj2g.jpg', // #hailcorporate
                'https://i.imgur.com/mn8gsgZ.jpg']
            }];
        }).property(),

        getAlbum: function getAlbum(id) {
            return this.get('albums').findBy('id', id);
        }
    });

    var albumManager = exports.albumManager = AlbumManager.create();
});