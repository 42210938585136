define('client/controllers/games/chess', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function set_for(ary) {
        var ret = {};

        ary.forEach(function (val) {
            ret[val] = true;
        });

        return ret;
    }

    // pieces
    var EMPTY = exports.EMPTY = 0;

    var PAWN = exports.PAWN = 1;
    var BISHOP = exports.BISHOP = 2;
    var KNIGHT = exports.KNIGHT = 3;
    var ROOK = exports.ROOK = 4;
    var KING = exports.KING = 5;
    var QUEEN = exports.QUEEN = 6;
    var PIECES = exports.PIECES = set_for([PAWN, BISHOP, KNIGHT, ROOK, KING, QUEEN]);

    // colors
    var WHITE = exports.WHITE = 'white';
    var BLACK = exports.BLACK = 'black';
    var COLORS = exports.COLORS = set_for([WHITE, BLACK]);

    // piece statuses
    var ACTIVE = exports.ACTIVE = 'active'; // if an available move
    var DANGER = exports.DANGER = 'danger'; // if an available capture -- pawns can only capture diagonal but can normally move forwards
    var INACTIVE = exports.INACTIVE = 'inactive';

    var Piece = Ember.Object.extend({
        color: null,
        piece: null,

        status: null
    });

    Piece.reopenClass({
        make: function make(piece, color) {
            if (!COLORS[color]) {
                return;
            }
            if (!PIECES[piece]) {
                return;
            }

            return Piece.create({
                color: color,
                piece: piece
            });
        }
    });

    exports.default = Ember.Controller.extend({
        board: Ember.computed(function () {
            // create empty board
            var ret = [];

            for (var i = 0; i < 8; i += 1) {
                var tmp = [];

                for (var j = 0; j < 8; j += 1) {
                    tmp.push(EMPTY);
                }

                ret.push(tmp);
            }

            // place in default pieces
            ret[0][0] = Piece.make(ROOK, BLACK);
            ret[0][1] = Piece.make(KNIGHT, BLACK);
            ret[0][2] = Piece.make(BISHOP, BLACK);
            ret[0][3] = Piece.make(QUEEN, BLACK);
            ret[0][4] = Piece.make(KING, BLACK);
            ret[0][5] = Piece.make(BISHOP, BLACK);
            ret[0][6] = Piece.make(KNIGHT, BLACK);
            ret[0][7] = Piece.make(ROOK, BLACK);
            ret[1][0] = Piece.make(PAWN, BLACK);
            ret[1][1] = Piece.make(PAWN, BLACK);
            ret[1][2] = Piece.make(PAWN, BLACK);
            ret[1][3] = Piece.make(PAWN, BLACK);
            ret[1][4] = Piece.make(PAWN, BLACK);
            ret[1][5] = Piece.make(PAWN, BLACK);
            ret[1][6] = Piece.make(PAWN, BLACK);
            ret[1][7] = Piece.make(PAWN, BLACK);
            ret[0][0] = Piece.make(ROOK, BLACK);

            ret[6][0] = Piece.make(ROOK, WHITE);
            ret[6][1] = Piece.make(KNIGHT, WHITE);
            ret[6][2] = Piece.make(BISHOP, WHITE);
            ret[6][3] = Piece.make(QUEEN, WHITE);
            ret[6][4] = Piece.make(KING, WHITE);
            ret[6][5] = Piece.make(BISHOP, WHITE);
            ret[6][6] = Piece.make(KNIGHT, WHITE);
            ret[6][7] = Piece.make(ROOK, WHITE);
            ret[7][0] = Piece.make(PAWN, WHITE);
            ret[7][1] = Piece.make(PAWN, WHITE);
            ret[7][2] = Piece.make(PAWN, WHITE);
            ret[7][3] = Piece.make(PAWN, WHITE);
            ret[7][4] = Piece.make(PAWN, WHITE);
            ret[7][5] = Piece.make(PAWN, WHITE);
            ret[7][6] = Piece.make(PAWN, WHITE);
            ret[7][7] = Piece.make(PAWN, WHITE);

            return ret;
        }).property()

        /*
             what should the game loop look like?
                 show whose turn it currently is
                click a piece, show the piece as selected, then see the available spaces
                click a destination space, confirm the move and make it
         */
    });
});