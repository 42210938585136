define('client/router', ['exports', 'client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberRouter = Ember.Router;


    var Router = EmberRouter.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('index', { path: '/' });

        this.route('recipes', function () {
            this.route('show', { path: '/:recipeId' });
        });

        this.route('photographs', function () {
            this.route('show', { path: '/:albumId' });
        });

        this.route('writing', function () {
            this.route('xxx');
        });

        this.route('games', function () {
            this.route('chess');
            this.route('tic_tac_toe', { path: '/tic-tac-toe' });
        });

        this.route('resume');
        this.route('writing');
        this.route('quotes');
    });

    exports.default = Router;
});