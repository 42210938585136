define('client/controllers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentName: Ember.computed(function () {
            var idx = this.get('nameIndex');
            var ary = this.get('names');

            idx = idx % ary.get('length');
            return ary[idx];
        }).property('names', 'nameIndex'),

        nameIndex: 0,
        names: Ember.computed(function () {
            return ['jonathan', 'jonathan collins', 'joco']; //, 'jcolls']
        }).property(),

        init: function init() {
            this._super.apply(this, arguments);
            var controller = this;

            function loop() {
                Ember.run.later(this, function () {
                    if (controller.get('isDestroyed')) {
                        return;
                    }

                    controller.incrementProperty('nameIndex');
                    loop();
                }, 5000);
            }

            loop();
        }
    });
});